<template>
  <div class="sectionPlayerContainer"
       style="">
    <div :class=" mirroring?'videoMirroring':'full'">
      <div :style="'background-image: url('+main+');'" class="thumbnail" :class="filterMainClass+' noAnimation '"></div>
      <div class="mask"></div>
      <div class="layer1"></div>
      <div class="layer2"></div>
      <div class="layer3"></div>
      <div class="layer4"></div>
      <div class="layer5"></div>
    </div>
    <div class="blackOverlay" :class="{processing:isProcessing}">
    </div>
    <div class="sticker" :style="'background-image:url('+ urlUser+')'" v-if="second!=='' && this.mustShowSticker">
      <div class="videoMirroring">
        <div :style="'background-image: url('+second+');'" class="thumbnail"
             :class="filterStickerClass+' noAnimation'"></div>
        <div class="mask"></div>
        <div class="layer1"></div>
        <div class="layer2"></div>
        <div class="layer3"></div>
        <div class="layer4"></div>
        <div class="layer5"></div>
      </div>
    </div>

    <transition name="fade">
      <div class="playLayout" v-show="mainProgress>=100">
        <w-icon icon="play-circle" size="small"/>
      </div>
    </transition>


    <!-- progress bar -->
    <transition name="fade">
      <div class="pourcentageValue" v-show="isProcessing">{{ mainProgressLabel }}</div>
    </transition>
    <transition name="fade">
      <div class="progressBar" :style="{width:mainProgress+'%'}" v-show="mainProgress<100"></div>
    </transition>
  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';
import {Segment, TimeEvent} from '@/store/timeLine/timeEvent';
import {Media} from '@/store/media/mediaModel';
import {getThumbnailURL, getUserAvatar, isImageExists} from '@/utils/util';
import UserAvatar from '@/components/user/UserAvatar.vue';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import store from '@/store';
import {getTimeEventExactTime} from '@/utils/timeLineUtils';

@Component({
  components: {UserAvatar, WIcon}
})
export default class ImageSection extends Vue {

  @Prop()
  private segment!: Segment;

  @Prop({default: false})
  private autoplay!: boolean;
  private mustShow: boolean = false;

  private main: string = '';
  private mainProgress: number = 0;
  private isProcessing: boolean = false;
  private second: string = '';
  private urlUser: string = '';
  private mustShowSticker: boolean = true;
  private MAX_ITERATION = 10;
  private indexTimeOut: any = 0;
  private filterStickerClass: string = '';
  private filterMainClass: string = '';
  private mirroring: boolean = false;


  public mounted() {
    this.mustShow = true;
    this.setupTheEventForTime();
  }

  get medias(): Media[] {
    return store.getters.getMedias;
  }

  get mainProgressLabel(): string {
    if (this.mainProgress == 0) {
      return this.$t('createWeetComponent.processing').toString();
    } else {
      return this.mainProgress + " %";
    }
  }

  @Watch('medias')
  private setupTheEventForTime() {
    const listEvent = store.getters.getTimeEvent;

    const updatedEvent = getTimeEventExactTime(listEvent, this.segment.start.time);
    if (updatedEvent) {
      this.setupThisEvent(updatedEvent);
    }

    if (updatedEvent?.mainMedia) {
      const media = store.getters.getMediasForMediaId(updatedEvent.mainMedia.mediaID) as Media;
      if (media) {
        this.mainProgress = media.progress;
        this.isProcessing = media.status !== "HD";
      }
    }
    if(!this.isProcessing && updatedEvent?.secondMedia){
      const media = store.getters.getMediasForMediaId(updatedEvent.secondMedia.mediaID) as Media;
      if (media) {
        this.mainProgress = media.progress;
        this.isProcessing = media.status !== "HD";
      }
    }
  }

  private async setupThisEvent(timeEvent: TimeEvent) {
    const urlMain = await this.getUrlMedia(timeEvent.mainMedia.mediaID, true);
    if (urlMain) {
      this.main = urlMain;
    }
    this.mustShowSticker = timeEvent.positionLayout !== StickerPosition.NONE;
    const urlSecond = await this.getUrlMedia(timeEvent.secondMedia.mediaID, false);
    if (urlSecond) {
      this.second = urlSecond;
    }
    if (timeEvent.secondMedia && timeEvent.secondMedia.mediaID) {
      this.filterStickerClass = timeEvent.secondMedia.filter;
    }
    if (timeEvent.mainMedia && timeEvent.mainMedia.mediaID) {
      this.filterMainClass = timeEvent.mainMedia.filter;

    }
    this.urlUser = this.getUrlUser(timeEvent.secondMedia.mediaID);
  }

  private async getUrlMedia(mediaId: string, main: boolean): Promise<string | undefined> {
    if (!mediaId) {
      return undefined;
    }
    for (const media of this.medias) {
      if (media.mediaID === mediaId) {
        if (main && media.type === SourceVideoType.WEBCAM) {
          this.mirroring = true;
        }
        if (media.imageBlobURL) {
          return media.imageBlobURL;
        } else {
          if (!media.jobID) {
            // so we wait have a blob ready or an status ready
            if (this.MAX_ITERATION > 0) {
              clearTimeout(this.indexTimeOut);
              this.indexTimeOut = setTimeout(this.setupTheEventForTime, 500);
              this.MAX_ITERATION--;
            }
            return undefined;
          } else {

            const urlImage = getThumbnailURL(media.jobID, true);
            if (media.status === 'HD') {
              return urlImage;
            } else {

              if (await isImageExists(urlImage)) {
                return urlImage;
              } else {
                return undefined;
              }
            }
          }
        }
      }
    }
  }


  private getUrlUser(mediaId: string): string {
    for (const media of this.medias) {
      if (media.mediaID === mediaId) {
        return getUserAvatar(media.creator, 48);
      }
    }
    return '';
  }
}
</script>


<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';
@import '@/scss/shadows.scss';


.sectionPlayerContainer {
  height: 100%;
  width: 100%;
  position: relative;
  background: var(--dark);
  border-radius: 4px;
  overflow: hidden;

  .thumbnail {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
  }

  .videoMirroring {
    width: 100%;
    height: 100%;
    transform: scale3d(-1, 1, 1);
  }

  .full {
    width: 100%;
    height: 100%;
  }

  .sticker {
    position: absolute;
    bottom: 4px;
    left: 4px;
    width: 24px;
    height: 24px;
    background-size: cover;
    // border: 1px solid white;
    @extend .stickersShadow;
    overflow: hidden;
    border-radius: 100% 100% 100% 4px;

    .videoMirroring {
      width: 24px;
      height: 24px;

      .videoElement {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
    }
  }

  .playLayout {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .pourcentageValue {
    position: absolute;
    width: 100%;
    color: white;
    text-align: center;
    bottom: 24px;
    font-weight: 900;
    left: 4px;
    font-size: 12px;
  }

  .progressBar {
    position: absolute;
    width: 100%;
    height: 3px;
    background: var(--primary);
    bottom: 0;
    left: 0;
  }

  .blackOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);

    &.processing {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  &:hover {
    opacity: 0.8;
  }

}


</style>
