<template>
  <transition name="fade">
    <div class="AiEditorContainer"
         v-show="streamLoading"
    >
      <div class="aiAvatarSelection">
        <!-- AVATAR SELECTION -->
        <div class="avatarBackground" :style="'background-image:url('+backgroundSelected.url+')'"></div>
        <div class="avatarPreview" :class="avatarSelectedClass"></div>
        <div class="avatarParameter">
          <div class="labelSelect">{{ $t('aiAvatar.styleSelector') }}</div>
          <b-dropdown :max-height="160" :position="'is-bottom-left'" :scrollable="true">
            <template #trigger>
              <div
                  class="avatarSelector"
                  role="button">
                <div class="avatarSelectorContent">
                  {{ getLabelForAvatar(avatarSelected, styleSelected) }}
                  <w-icon color="light2" icon="chevron-down"></w-icon>
                </div>
              </div>
            </template>
            <b-dropdown-item v-for="(avatar,index) in listOfAvatar"
                             :key="'avatar_'+avatar.avatar+index">
              <div class="avatarSelection" @click="onAvatarSelected(avatar)">
                <span class="avatarSelectionImage" :style="'background-image:url('+avatar.img+')'"/>
                <span class="avatarSelectionLabel">
                     {{ avatar.label }}
                  </span>
                <div class="avatarSelected" v-if="avatar.avatar===avatarSelected && avatar.style===styleSelected">
                  <w-icon icon="check" color="primary"/>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>

          <div class="labelSelect">{{ $t('aiAvatar.background') }}</div>
          <b-dropdown :max-height="120" :position="'is-bottom-left'" :scrollable="true">
            <template #trigger>
              <div
                  class="backgroundSelector"
                  role="button">
                <div class="backgroundSelectorContent">
                  {{ backgroundSelected.label }}
                  <w-icon color="light2" icon="chevron-down"></w-icon>
                </div>
              </div>
            </template>
            <b-dropdown-item v-for="background in listOfBackground"
                             :key="'background_'+background.background">
              <div class="backgroundSelection" @click="onBackgroundSelected(background)">
                <span class="backgroundSelectionImage" :style="'background-image:url('+background.url+')'"/>
                <span class="backgroundSelectionLabel">
                    {{ background.label }}
                  </span>
                <div class="backgroundSelected" v-if="background===backgroundSelected">
                  <w-icon icon="check" color="primary"/>
                </div>
              </div>

            </b-dropdown-item>
          </b-dropdown>

          <b-upload v-if="isExperimentalFeature" drag-drop style="margin-top: 16px;width: 100%;text-align: center"
                    @input="uploadAPdf" accept=".pdf" :loading="isProceedSlideInProgress">
            <p v-if="!currentSlide && !isProceedSlideInProgress">
              <w-icon icon="upload" color="dark"/>
            </p>
            <p v-if="!currentSlide  && !isProceedSlideInProgress ">TODO, Upload a slide (.pdf)</p>
            <p v-if="currentSlide"><img v-if="currentSlide" alt="pdf uploaded" :src="currentSlideImage"
                                        style="max-width: 100%;max-height: 136px"></p>
          </b-upload>
        </div>
      </div>

      <!-- CONTENT CONTENT EDITING -->
      <div class="aiAvatarContentEdition" v-if="isContentEditingOpen && !isProceedSlideInProgress">
        <div class="labelTypeYourText">
          <div class="labelSelect">{{ $t('aiAvatar.langsSelector') }}</div>
          <w-select class="langSelector" v-model="langSelected" size="small">
            <option :value="listOfVoicesLangs[0].value" :key="'voice_lang_'+listOfVoicesLangs[0].value">
              {{ listOfVoicesLangs[0].label }}
            </option>
            <hr>
            <option v-for="lang in listOfVoicesLangs.slice(1)" :value="lang.value" :key="'voice_lang_'+lang.value">
              {{ lang.label }}
            </option>
          </w-select>

          <div class="labelSelect labelVoiceSelector">{{ $t('aiAvatar.voicesSelector') }}</div>
          <w-select class="voiceSelector" v-model="voiceSelected" size="small">
            <option v-for="voice in listOfVoice" :value="voice.value" :key="'voice_'+voice.value">
              {{ voice.label }}
            </option>
          </w-select>
          <w-button :icon="playingVoice?'stop':'volume-high'" :enabled="textToGenerate"
                    @click="previewVoice"
                    class="buttonPlayPreview" size="small" color="outlined"
                    :loading="loadingVoice"></w-button>
        </div>
        <w-input type="textarea" v-model="textToGenerate" @input="onTextInput" class="contentTextAreaAI"
                 :placeholder="$t('aiAvatar.content.placeholder')"
                 :maxlength="maxlengthOfCharacter"></w-input>
        <div v-if="isFreemium" class="upgradeMessage">{{ $t('aiAvatar.content.upgrade') }}</div>
        <div class="tipItem" v-if="isFreemium">
          <upgrade-plan-button size="small" color="secondary"/>
        </div>
        <w-button :expanded="true" @click="launchAvatarGeneration" :enabled="!!textToGenerate"
        :loading="isAvatarGenerating">
          TODO generate slide
        </w-button>
      </div>


    </div>
  </transition>

</template>

<script lang="ts">
import store from '@/store';
import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import {SourceVideoType} from '@/enum/SourceVideoEnum';


import {
  CLEAR_MAIN_MEDIAID, CLEAR_STICKER_MEDIAID,
  GENERATE_MAIN_MEDIAID, GENERATE_STICKERS_MEDIAID,
} from '@/store/recordingState/recordStateAction';
import VideoFilePlayer from '@/components/recorder/videoLayout/videoPlayer/VideoFilePlayer.vue';
import WSelect from "@/components/wrapper/w-select.vue";
import WButton from "@/components/wrapper/w-button.vue";
import WInput from "@/components/wrapper/w-input.vue";
import WIcon from "@/components/wrapper/w-icon.vue";
import UpgradePlanButton from "@/components/upgradePlan/UpgradePlanButton.vue";
import {isFreemium} from "@/utils/workspaceUtil";
import {
  AiavatarGenerationModel,
  Avatar, AvatarGender,
  AvatarStyle,
  AvatarVoiceAvatar, AvatarLanguage, getLabelForAvatar, LIST_OF_AVATAR, LIST_OF_BACKGROUND, Text2SpeachParams
} from "@/store/AIAvatar/aiavatarGenerationModel";
import {initWeetEditing} from "@/utils/createWeetUtil";
import {Media} from "@/store/media/mediaModel";
import {
  REFRESH_MEDIA, TEXT_2_SPEECH_GENERATE, UPDATE_METADATA,
} from "@/store/media/mediaAction";
import {SAVE_TIMELINE_IF_NEED, TIMELINE_UNSAVED} from "@/store/timeLine/timeLineAction";
import CenterCenter from "@/components/layout/CenterCenter.vue";
import {alertErrorWithConfirmation} from "@/utils/dialog";
import WToolTip from "@/components/wrapper/w-toolTip.vue";
import {listOfVoice, listOfVoicesLanguages} from "@/utils/voiceUtil";
import {base64ToBlob} from "@/utils/audioUtil";
import {Howl} from 'howler';
import {SpeachEvent, SpeachEventAvatarDuration, SpeachEventName} from "@/speach/speachEvent";
import {isSpeach} from "@/utils/speachUtils";
import delay from "delay";
import {Slide} from "@/store/slides2Video/slides2VideoModels";
import {RESET_SLIDES_2_VIDEO, SELECT_SLIDE, SLIDES_TO_LLM} from "@/store/slides2Video/slides2VideoAction";
import {
  AIAVATAR_GENERATE,
  AVATAR_EDITING_TEXT,
  AVATAR_GENERATING, OPEN_AVATAR_EDITING_CONTENT,
  VIDEO_2_SLIDE_GENERATE
} from "@/store/AIAvatar/AIAVatarAction";

@Component({
  components: {WToolTip, CenterCenter, UpgradePlanButton, WIcon, WInput, WButton, WSelect, VideoFilePlayer},
  computed: {},
})
export default class AIAvatarSource extends Vue {


  private listOfBackground = LIST_OF_BACKGROUND;

  private voiceSelected = AvatarVoiceAvatar.EN_US_AMANDA
  private langSelected: AvatarLanguage | null = this.listOfVoicesLangs[0].value;
  private avatarSelected = Avatar.MEG
  private styleSelected = AvatarStyle.FORMAL
  private versionAvatar: number = 1;
  private backgroundSelected = this.listOfBackground[2];
  private gender = AvatarGender.FEMALE;
  private textToGenerate: string = "";
  private loadingVoice = false;
  private playingVoice = false;
  private audioPlayer: Howl | null = null;
  // value if the stream is setup
  private streamLoading: boolean = false;

  private MAX_LENGTH_FREEMIUM = 250;
  private MAX_LENGTH_PREMIUM = isSpeach() ? 2000 : 1000;

  get listOfVoice() {
    return listOfVoice.filter((voice) => {
      return voice.gender === this.gender
    })
  }

  get listOfAvatar() {
    return LIST_OF_AVATAR.filter((avatar) => {
      if (avatar.version > 1 && !this.isExperimentalFeature) {
        return false;
      } else {
        return true;
      }
    })
  }

  get listOfVoicesLangs() {
    const autoDetectOption = {label: "Auto detect", value: null};
    return [autoDetectOption, ...listOfVoicesLanguages];
  }

  get isExperimentalFeature() {
    return store.getters.isExperimentalFeature
  }

  get isContentEditingOpen() {
    return store.getters.isOpenAvatarEditionContent
  }

  get isAvatarGenerating() {
    return store.getters.isAvatarGenerating
  }

  get avatarSelectedClass() {
    return this.avatarSelected + "_" + this.styleSelected;
  }

  get isFreemium(): boolean {
    return isFreemium()
  }

  get maxlengthOfCharacter() {
    if (this.isFreemium) {
      return this.MAX_LENGTH_FREEMIUM;
    } else {
      return this.MAX_LENGTH_PREMIUM;
    }
  }

  get isProceedSlideInProgress() {
    return store.getters.isProceedSlideInProgress;
  }

  get slides(): Slide[] {
    return store.getters.slides;
  }

  get currentSlide(): Slide | null {
    if (store.getters.getCurrentSlideIndex > -1) {
      return store.getters.slides[store.getters.getCurrentSlideIndex];
    }
    return null;
  }

  get currentSlideImage(): string {
    if (this.currentSlide) {
      return "data:image/jpg;base64," + this.currentSlide.imageB64;
    } else {
      return "";
    }
  }


  public getLabelForAvatar(avatar: Avatar, style: AvatarStyle) {
    return getLabelForAvatar(avatar, style);
  }


  public async mounted() {
    this.streamLoading = true;
    this.textToGenerate = store.getters.getAiAvatarText

  }

  private onAvatarSelected(value: any) {
    this.avatarSelected = value.avatar;
    this.styleSelected = value.style
    this.versionAvatar = value.version;
    if (this.gender != value.gender) {
      this.gender = value.gender;
      this.voiceSelected = this.listOfVoice[0].value;
      this.langSelected = this.listOfVoicesLangs[0].value;
    }
  }

  private onBackgroundSelected(value: any) {
    this.backgroundSelected = value;
  }

  private async initWeetEditing() {
    if (store.getters.getEditingWeetID === '') {
      await initWeetEditing()
    }
  }


  private async uploadAPdf(file: File) {
    store.dispatch(OPEN_AVATAR_EDITING_CONTENT, true)
    await store.dispatch(SLIDES_TO_LLM, {file, ext: ".pdf"})
  }

  @Watch("currentSlide")
  private onCurrentSlideChange(){
    if(this.currentSlide){
      this.textToGenerate=this.currentSlide.script
    }else{
      this.textToGenerate="";
    }
    store.dispatch(AVATAR_EDITING_TEXT,this.textToGenerate);
  }


  private onTextInput() {
    store.dispatch(AVATAR_EDITING_TEXT, this.textToGenerate);
  }

  private async stopPreviewVoice() {

    // STOP
    if (this.audioPlayer) {
      this.audioPlayer.stop();
      this.audioPlayer = null;
      this.playingVoice = false;
    }
  }

  private async previewVoice() {
    if (this.playingVoice) {
      this.stopPreviewVoice();
      return;
    } else {
      this.stopPreviewVoice();
    }

    // STOP
    if (this.audioPlayer) {
      this.audioPlayer.stop();
      this.audioPlayer = null;
      this.playingVoice = false;
      return;
    }
    this.loadingVoice = true;
    const text2SpeachParams = new Text2SpeachParams()
    text2SpeachParams.text = this.textToGenerate;
    text2SpeachParams.voice = this.voiceSelected;
    text2SpeachParams.lang = this.langSelected;
    const base64 = await store.dispatch(TEXT_2_SPEECH_GENERATE, text2SpeachParams);
    const url = URL.createObjectURL(base64ToBlob(base64, 'audio/mp3'));
    this.loadingVoice = false;
    this.audioPlayer = new Howl({
      src: [url],
      autoplay: true,
      html5: true,
      onend: () => {
        this.playingVoice = false;
      },
      volume: 1,
    });
    this.audioPlayer.play();
    this.playingVoice = true;
  }

  public async launchAvatarGeneration() {
    this.stopPreviewVoice();
    if (this.isAvatarGenerating) { //if not step loading
      return;
    }
    if (this.textToGenerate) {
      try {
        await this.initWeetEditing(); // obtain a weet ID
        let mediaAvatar = new Media();
        if (!this.currentSlide) { // Avatar Only
          await store.dispatch(CLEAR_STICKER_MEDIAID);
          await store.dispatch(GENERATE_MAIN_MEDIAID);
          // create a media
          mediaAvatar.mediaID = store.getters.getMainMediaId;
          mediaAvatar.type = SourceVideoType.AI_AVATAR;
        } else {
          await store.dispatch(CLEAR_STICKER_MEDIAID);
          await store.dispatch(CLEAR_MAIN_MEDIAID);
          await store.dispatch(GENERATE_MAIN_MEDIAID)
          await store.dispatch(GENERATE_STICKERS_MEDIAID)

          mediaAvatar.mediaID = store.getters.getStickersMediaId;
          mediaAvatar.type = SourceVideoType.AI_AVATAR;
        }

        // Launch avatar generation
        var params = new AiavatarGenerationModel(this.textToGenerate,
            this.avatarSelected, this.voiceSelected, this.langSelected, this.styleSelected,
            this.backgroundSelected.url, this.versionAvatar);
        // Todo if slide generate an SD version
        mediaAvatar = await store.dispatch(AIAVATAR_GENERATE, {media: mediaAvatar, aiavatarParams: params});
        console.log("RefreshedMedia: ", mediaAvatar)
        await store.dispatch(TIMELINE_UNSAVED);
        await store.dispatch(REFRESH_MEDIA, mediaAvatar);

        // compute duration of voice over
        // TODO find a better way... because we paid 2 times for that
        const seconds = await this.getDurationForText();
        const metadMediaMetadata = {status: "WAITING", duration: seconds};
        await store.dispatch(UPDATE_METADATA, metadMediaMetadata);


        // GEENRATE SLIDE
        if (this.currentSlide) {
          let mediaSlide = new Media()
          mediaSlide.type = SourceVideoType.VIRTUAL_SCREENCAST
          mediaSlide.mediaID = store.getters.getMainMediaId
          mediaSlide = await store.dispatch(VIDEO_2_SLIDE_GENERATE, {
            mediaSlide,
            b64: this.currentSlide.imageB64,
            seconds
          })

          // const metadMediaMetadata = {status: "WAITING", duration: seconds};
          // await store.dispatch(UPDATE_METADATA, metadMediaMetadata);

          await store.dispatch(TIMELINE_UNSAVED);
          await delay(2000);
          await store.dispatch(REFRESH_MEDIA, mediaSlide);
        }

        // clear text
        await store.dispatch(AVATAR_EDITING_TEXT, "");
        this.textToGenerate = "";

        // clear slide
        // await store.dispatch(RESET_SLIDES_2_VIDEO)

        if(this.currentSlide ) {
          if(this.currentSlide.order<this.slides.length) {
            store.dispatch(SELECT_SLIDE,this.currentSlide.order);
          }else{
            store.dispatch(RESET_SLIDES_2_VIDEO)
          }
        }
        // send time to speach
        const dataEvent = new SpeachEventAvatarDuration(store.getters.getEditingWeetID, seconds)
        window.parent.postMessage(new SpeachEvent(SpeachEventName.AIAVATAR_DURATION, dataEvent), "*")

        await store.dispatch(AVATAR_GENERATING, false)


        await store.dispatch(TIMELINE_UNSAVED);
        await store.dispatch(SAVE_TIMELINE_IF_NEED);

        this.$emit('close');
      } catch (e) {
        console.error(e)
        store.dispatch(AVATAR_GENERATING, false)
        alertErrorWithConfirmation(this.$t('aiAvatar.content.error').toString()).then(() => {
          this.$emit('close');
        })
      }
    }
  }


  private async getDurationForText(): Promise<number> {
    return new Promise(async (resolve, reject) => {
      this.stopPreviewVoice();
      const text2SpeachParams = new Text2SpeachParams()
      text2SpeachParams.text = this.textToGenerate;
      text2SpeachParams.voice = this.voiceSelected;
      const base64 = await store.dispatch(TEXT_2_SPEECH_GENERATE, text2SpeachParams);
      const url = URL.createObjectURL(base64ToBlob(base64, 'audio/mp3'));
      this.audioPlayer = new Howl({
        src: [url],
        autoplay: true,
        html5: true,
        onload: () => {
          this.audioPlayer.stop();
          resolve(this.audioPlayer.duration());
        },
        onend: () => {
          this.playingVoice = false;
        },
        volume: 0,
      });
      this.audioPlayer.play();
    })

  }

  beforeDestroy() {
    this.stopPreviewVoice();
  }
}
</script>

<style lang="scss">
// no scope to manage textarea size
.contentTextAreaAI {
  .textarea {
    height: 380px;
    resize: none;
  }
}


</style>
<style lang="scss" scoped>
@import "@/scss/shadows.scss";

.AiEditorContainer {
  display: flex;
  width: 100%;
  animation-duration: 1s;
  border-radius: 8px;


  .aiAvatarSelection {
    width: 100%;
    min-height: 314px;
    background: var(--light);
    border-radius: 8px;

    .avatarParameter {
      width: 232px;
      text-align: right;
      position: absolute;
      right: 16px;
      top: 16px;
      padding: 16px;
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(12px);
      border-radius: 16px;

      .paramsSelect {
        margin-bottom: 24px;
        width: 200px;
      }

      .buttonNextStep {
        position: absolute;
        right: 16px;
        bottom: 16px;
      }

      .labelSelect {
        position: relative;
        text-align: left;
        font-size: 14px;
        color: var(--dark);
        justify-content: space-between;
        align-items: flex-end;
        display: flex;
      }
    }

    .avatarBackground {
      position: absolute;
      width: 100%;
      left: 0px;
      height: 100%;
      background-size: cover;
      border-radius: 8px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        border-radius: 8px;
      }
    }

    .avatarPreview {
      position: absolute;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 0px center;

      &.harry_business {
        background-image: url(@/assets/AIAvatar/HARRY-BUSINESS.png);
      }

      &.harry_casual {
        background-image: url(@/assets/AIAvatar/HARRY-CASUAL.png);
      }

      &.harry_youthful {
        background-image: url(@/assets/AIAvatar/HARRY-YOUTHFUL.png);
      }

      &.jeff_business {
        background-image: url(@/assets/AIAvatar/JEFF-BUSINESS.png);
      }

      &.jeff_formal {
        background-image: url(@/assets/AIAvatar/JEFF-FORMAL.png);
      }

      &.lori_casual {
        background-image: url(@/assets/AIAvatar/LORI-CASUAL.png);
      }

      &.lori_formal {
        background-image: url(@/assets/AIAvatar/LORI-FORMAL.png);
      }

      &.lori_graceful {
        background-image: url(@/assets/AIAvatar/LORI-GRACEFUL.png);
      }

      &.max_business {
        background-image: url(@/assets/AIAvatar/MAX-BUSINESS.png);
      }

      &.max_casual {
        background-image: url(@/assets/AIAvatar/MAX-CASUAL.png);
      }

      &.max_formal {
        background-image: url(@/assets/AIAvatar/MAX-FORMAL.png);
      }

      &.meg_business {
        background-image: url(@/assets/AIAvatar/MEG-BUSSINESS.png);
      }

      &.meg_casual {
        background-image: url(@/assets/AIAvatar/MEG-CASUAL.png);
      }

      &.meg_formal {
        background-image: url(@/assets/AIAvatar/MEG-FORMAL.png);
      }

      &.lisa_graceful-standing {
        background-image: url(@/assets/AIAvatar/LISA_GRACEFUL_STANDING.png);
      }

      &.lisa_casual-sitting {
        background-image: url(@/assets/AIAvatar/LISA_CASUAL_SITTING.png);
      }

      &.lisa_graceful-sitting {
        background-image: url(@/assets/AIAvatar/LISA_GRACEFUL_SITTING.png);
      }

      &.lisa_technical-standing {
        background-image: url(@/assets/AIAvatar/LISA_TECHNICAL_STANDING.png);
      }

      &.lisa_technical-sitting {
        background-image: url(@/assets/AIAvatar/LISA_TECHNICAL_SITTING.png);
      }

      // V2
      &.abigail_static {
        background-image: url(@/assets/AIAvatar/ABIGAIL.png);
      }

      &.arthur_static {
        background-image: url(@/assets/AIAvatar/ARTHUR.png);
      }

      &.darnel_static {
        background-image: url(@/assets/AIAvatar/DARNEL.png);
      }

      &.hannah_static {
        background-image: url(@/assets/AIAvatar/HANNAH.png);
      }

      &.marc_static {
        background-image: url(@/assets/AIAvatar/MARC.png);
      }

      &.isabella_static {
        background-image: url(@/assets/AIAvatar/ISABELLA.png);
      }

      &.jen_static {
        background-image: url(@/assets/AIAvatar/JEN.png);
      }

      &.marie_static {
        background-image: url(@/assets/AIAvatar/MARIE.png);
      }

      &.martha_static {
        background-image: url(@/assets/AIAvatar/MARTHA.png);
      }

      &.michelle_static {
        background-image: url(@/assets/AIAvatar/MICHELLE.png);
      }

      &.mike_static {
        background-image: url(@/assets/AIAvatar/MIKE.png);
      }
    }
  }

  .backgroundSelector, .avatarSelector {
    background: white;
    border-radius: 8px;
    padding: 2px 8px;
    margin-top: 4px;
    border: solid 1px var(--light1);
    min-width: 200px;
    font-size: 12px;
    cursor: pointer;
    color: var(--dark);

    .backgroundSelectorContent, .avatarSelectorContent {
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: capitalize;
    }
  }

  .backgroundSelection, .avatarSelection {
    display: flex;
    align-items: center;

    .backgroundSelectionImage, .avatarSelectionImage {
      border: 1px solid var(--light1);
      display: block;
      width: 32px;
      height: 32px;
      background: var(--light);
      background-position: center center;
      margin-right: 16px;
      border-radius: 4px;
      background-size: cover;
    }

    .backgroundSelectionLabel, .avatarSelectionLabel {
      font-size: 14px;
      flex: 1;
      color: var(--dark);
    }

    .backgroundSelected, .avatarSelectionSelected {
      margin-right: 0px;
    }
  }

  .aiAvatarContentEdition {
    position: absolute;
    min-height: 314px;
    max-width: 314px;
    right: -400px;
    top: -100px;
    padding: 16px;
    background: var(--light);
    border-radius: 8px;

    .labelTypeYourText {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      text-align: left;
      font-weight: 700;
      color: var(--black);

      .buttonBack {
        margin-right: 16px;
      }

      .iconInformation {
        margin-left: 8px;
        display: flex;
        margin-right: 56px;
      }

      .langSelector, .voiceSelector {
        margin-bottom: 0px;
        margin-left: 8px;
      }

      .langSelector {
        width: 167px;
      }

      .voiceSelector {
        width: 140px;
      }

      .buttonPlayPreview {
        margin-left: 8px;
        cursor: pointer;
      }

      .labelSelect {
        position: relative;
        text-align: left;
        font-size: 14px;
        color: var(--black);
        font-weight: 600;
        justify-content: space-between;
        align-items: flex-end;
        display: flex;

        &.labelVoiceSelector {
          margin-left: 16px;
        }
      }
    }

    .upgradeMessage {
      font-size: 14px;
      color: var(--light2);
    }

    .tipItem {
      position: absolute;
      right: 16px;
      bottom: 16px;
    }

    .contentTextAreaAI {
      margin-bottom: -16px;
    }

    .buttonValidateStep {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }
  }
}

</style>
