import {
  AIAVATAR_GENERATE,
  AVATAR_EDITING_TEXT, AVATAR_GENERATING,
  OPEN_AVATAR_EDITING_CONTENT, VIDEO_2_SLIDE_GENERATE
} from "@/store/AIAvatar/AIAVatarAction";
import {CREATE_MEDIA, MEDIA_UPDATE_MEDIAID} from "@/store/media/mediaAction";
import {Media} from "@/store/media/mediaModel";
import {AiavatarGenerationModel} from "@/store/AIAvatar/aiavatarGenerationModel";
import axios from "axios";
import {MediaIDUpdateParams, TIMELINE_UPDATE_MEDIAID} from "@/store/timeLine/timeLineAction";
import {UPDATE_MEDIA_ID_RECORDING_STATE} from "@/store/recordingState/recordStateAction";
import {
  ATTACHED_MEDIA_TO_WEET,
  ATTACHED_TIMELINE_TO_WEET,
  AttachedMediaParams,
  AttachedTimeLineParams
} from "@/store/myWeet/myWeetAction";
import {log} from "@/utils/log";
import store from "@/store";
import {AIAVATAR_API, SLIDE2VIDEO_API} from "@/store/media/mediaModule";


export default {
  state: {
    openAvatarEditingContent:false,
    aiAvatarTextContent:"",
    avatarGenerating:false
  },
  mutations: {
    [OPEN_AVATAR_EDITING_CONTENT]:(state:any,value:boolean)=>{
      state.openAvatarEditingContent=value;
    },
    [AVATAR_EDITING_TEXT]:(state:any,text:string)=>{
      state.aiAvatarTextContent=text;
    },
    [AVATAR_GENERATING]:(state:any,value:boolean)=>{
      state.avatarGenerating=value;
    },
  },
  getters: {
    isOpenAvatarEditionContent: (state: any): boolean => {
      return state.openAvatarEditingContent;
    },
    getAiAvatarTextContent: (state: any): string => {
      return state.aiAvatarTextContent;
    },
    isAvatarGenerating:(state:any):string=>{
      return state.avatarGenerating;
    }
  },
  actions: {
    [OPEN_AVATAR_EDITING_CONTENT](
        {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
        value: boolean
    ) {
      commit(OPEN_AVATAR_EDITING_CONTENT, value);
    },
    [AVATAR_EDITING_TEXT](
        {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
        text: string
    ) {
      commit(AVATAR_EDITING_TEXT, text);
    },
    [AVATAR_GENERATING](
        {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
        value: boolean
    ) {
      commit(AVATAR_GENERATING, value);
    },

    async [AIAVATAR_GENERATE]({
                                commit,
                                dispatch,
                                state,
                                getters
                              }: { commit: any, dispatch: any, state: any, getters: any },
                              {media, aiavatarParams}: { media: Media, aiavatarParams: AiavatarGenerationModel }) {

      if (getters.getEditingWeetID === '') {
        // so there is a huge problem here
        throw Error('Impossible to create aiavatar if there is no weet ID');
      }
      commit(CREATE_MEDIA, media);
      commit(AVATAR_GENERATING, true)
      const response = await axios(
          {
            url: AIAVATAR_API,
            method: 'POST',
            data: aiavatarParams
          }).then(async (resp) => {

        const refreshMedia = resp.data;
        refreshMedia.saved = true;
        // now we update the mediaID
        const params = new MediaIDUpdateParams(media.mediaID, refreshMedia);

        await commit(MEDIA_UPDATE_MEDIAID, params);
        await dispatch(UPDATE_MEDIA_ID_RECORDING_STATE, params);

        await dispatch(TIMELINE_UPDATE_MEDIAID, params);


        const attachedParams = new AttachedMediaParams(getters.getEditingWeetID, params.media.mediaID);
        await dispatch(ATTACHED_MEDIA_TO_WEET, attachedParams);

        log.debug('Is All Media Saved = ' + getters.isAllMediaSavedAndValidate + ' and Validate');
        if (getters.isAllMediaSavedAndValidate && getters.timelineNeedToBeSaved) {
          // after the media is really update we start the synchronisation of the timeline
          const attachedTimeLineParams = new AttachedTimeLineParams(
              getters.getEditingWeetID,
              getters.getTimeEvent,
              getters.getOffsetTimeEventUpdate
          );
          store.dispatch(ATTACHED_TIMELINE_TO_WEET, attachedTimeLineParams);
        }
        return refreshMedia;
      }).catch((err) => {
        commit(AVATAR_GENERATING, false)
        throw err;
      });
      return response;
    },
    async [VIDEO_2_SLIDE_GENERATE]({
                                     commit,
                                     dispatch,
                                     state,
                                     getters
                                   }: { commit: any, dispatch: any, state: any, getters: any },
                                   {mediaSlide, b64, seconds}: { mediaSlide: Media,b64:string, seconds: number }) {

      if (getters.getEditingWeetID === '') {
        // so there is a huge problem here
        throw Error('Impossible to create aiavatar if there is no weet ID');
      }
      commit(CREATE_MEDIA, mediaSlide);
      const response = await axios(
          {
            url: SLIDE2VIDEO_API,
            method: 'POST',
            data: {b64,seconds}
          }).then(async (resp) => {
        const refreshMedia = resp.data;
        refreshMedia.saved = true;
        // now we update the mediaID
        const params = new MediaIDUpdateParams(mediaSlide.mediaID, refreshMedia);
        await commit(MEDIA_UPDATE_MEDIAID, params);
        await dispatch(UPDATE_MEDIA_ID_RECORDING_STATE, params);

        await dispatch(TIMELINE_UPDATE_MEDIAID, params);


        const attachedParams = new AttachedMediaParams(getters.getEditingWeetID, params.media.mediaID);
        await dispatch(ATTACHED_MEDIA_TO_WEET, attachedParams);
        log.debug('Is All Media Saved = ' + getters.isAllMediaSavedAndValidate + ' and Validate');
        if (getters.isAllMediaSavedAndValidate && getters.timelineNeedToBeSaved) {
          // after the media is really update we start the synchronisation of the timeline
          const attachedTimeLineParams = new AttachedTimeLineParams(
              getters.getEditingWeetID,
              getters.getTimeEvent,
              getters.getOffsetTimeEventUpdate
          );
          store.dispatch(ATTACHED_TIMELINE_TO_WEET, attachedTimeLineParams);
        }
        return refreshMedia;
      }).catch((err) => {
        throw err;
      });
      return response;
    },
  },
};
