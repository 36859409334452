import axios from "axios";
import {BACK_ENDPOINT} from "@/store/conf/api";
import delay from "delay";
import {Slide} from "@/store/slides2Video/slides2VideoModels";
import {
  RESET_SLIDES_2_VIDEO, SELECT_SLIDE,
  SLIDES_TO_LLM,
  SLIDES_TO_LLM_IN_PROGRESS,
  SLIDES_TO_LLM_RESPONSE
} from "@/store/slides2Video/slides2VideoAction";
import {Vue} from "vue-property-decorator";
export const SLIDE_API = BACK_ENDPOINT + '/slides';


export default {
  state: {
    proceedSlideInProgress:false,
    slides:[],
    currentSlideIndex:-1
  },
  mutations: {
    [SLIDES_TO_LLM_IN_PROGRESS]: (state: any, value: boolean) => {
      state.proceedSlideInProgress=value;
    },
    [SLIDES_TO_LLM]: (state: any, value: Slide[]) => {
      Vue.set(state,'slides',value);
    },
    [SELECT_SLIDE]:(state:any,index:number)=>{
      state.currentSlideIndex=index;
    }
  },
  getters: {
    isProceedSlideInProgress: (state: any): boolean => {
      return state.proceedSlideInProgress;
    },
    slides:(state: any):Slide[] => {
      return state.slides
    },
    getCurrentSlideIndex(state: any):number {
      return state.currentSlideIndex;
    }
  },
  actions: {
    [RESET_SLIDES_2_VIDEO]({commit, dispatch}: { commit: any, dispatch: any }) {
      commit(SLIDES_TO_LLM,[])
      commit(SELECT_SLIDE,-1)
    },
    [SELECT_SLIDE]({commit, dispatch}: { commit: any, dispatch: any },index:number) {
      commit(SELECT_SLIDE,index)
    },
    async [SLIDES_TO_LLM]({commit, dispatch}: { commit: any, dispatch: any }, {file,ext}:{file:Blob,ext:string}) {
      const formData = new FormData();
      formData.append('slides', file);
      commit(SLIDES_TO_LLM_IN_PROGRESS,true)
      await axios({
        url: SLIDE_API + '/slides2LLM',
        params: {ext: ext},
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': 'form-data; name=slides',
        },
      }).then(async (resp) => {
        const jobID=resp.data;
        // now wait the slide proceed by llm
        const DELAY=2000;
        let llmResponse="";
        while(!llmResponse){
          await delay(DELAY);
          llmResponse=await dispatch(SLIDES_TO_LLM_RESPONSE,jobID)
        }
        commit(SLIDES_TO_LLM,llmResponse)
        commit(SELECT_SLIDE,0)
        console.log(llmResponse)
        commit(SLIDES_TO_LLM_IN_PROGRESS,false)
        return llmResponse;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        commit(SLIDES_TO_LLM_IN_PROGRESS,false)
        throw err;
      });
    },
    async [SLIDES_TO_LLM_RESPONSE]({commit, dispatch}: { commit: any, dispatch: any }, jobID:string) {
      return await axios({
        url: SLIDE_API + '/slides2LLM/'+jobID,
        method: 'GET',
      }).then((resp) => {
        const llmData=resp.data;
        // now wait the slide proceed by llm
        if(llmData==="ERROR"){
          throw new Error("Impossible to proceed this documents")
        }
        if(llmData){
          const decodedString = atob(llmData  );
          const jsonObject = JSON.parse(decodedString);
          return jsonObject
        }
        return
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
  },
};
