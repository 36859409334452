export class SpeachEvent{
    private name:SpeachEventName;
    private data:any;
    constructor(name:SpeachEventName,data:any) {
        this.name=name;
        this.data=data;
    }
}

export class SpeachEventAvatarDuration{
    private weetID:string;
    private seconds:number;
    constructor(weetID:string, seconds:number) {
        this.weetID=weetID;
        this.seconds=seconds;
    }
}

export class SpeachModification{
    private weetID:string;
    private modified:boolean;
    private duration:number;

    constructor(weetID:string, modified:boolean,duration:number) {
        this.weetID=weetID;
        this.modified=modified;
        this.duration=duration;
    }
}

export enum SpeachEventName{
    SEND_WEET_ID="SEND_WEET_ID",
    WEET_PLAYING="WEET_PLAYING",
    WEET_END="WEET_END",
    WEET_PAUSING="WEET_PAUSING",
    SILENTLY_SEND_WEET_ID="SILENTLY_SEND_WEET_ID",
    EDIT_WEET='EDIT_WEET',
    AIAVATAR_DURATION='AIAVATAR_DURATION'
}
